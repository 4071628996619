import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Tavie Gray" />
    <h1 className="pt-10 text-4xl font-extrabold text-gray-900">Tavie Gray</h1>
    <div className="max-w-screen-lg text-lg sm:text-2xl sm:leading-10 font-medium mb-10 sm:mb-11">
      <p>The old computer didn't explode when she flicked it on. That was a start.</p>
      <p></p>
    </div>
    <div>
      <iframe height="400px" width="100%" src="https://repl.it/@whatrocks/Hello-World?lite=true" scrolling="no" frameborder="no" allowtransparency="true" allowfullscreen="true" sandbox="allow-forms allow-pointer-lock allow-popups allow-same-origin allow-scripts allow-modals"></iframe>
    </div>
    <div>
      <h2 className="pt-10 text-2xl font-extrabold text-gray-900">Resources from the Little Bighill Public Library</h2>
    </div>
    <div className="p-12 flex flex-row flex-wrap">
      <a 
        href="https://www.fortcrawley.com"
        target="_blank"
        className="p-6 max-w-sm mx-auto bg-white rounded-xl shadow-md flex items-center space-x-4"
      >
        <div className="flex-shrink-0">
          <p className="text-5xl h-12 w-12">🖥️</p>
        </div>
        <div>
          <div className="text-xl font-medium text-black">Fort Crawley</div>
          <p className="text-gray-500">ARPANET-Internet Terminal</p>
        </div>
      </a>
      <a
        href="https://www.iterationcorporation.com"
        target="_blank"
        className="p-6 max-w-sm mx-auto bg-white rounded-xl shadow-md flex items-center space-x-4"
      >
        <div className="flex-shrink-0">
          <p className="text-5xl h-12 w-12">🕰️</p>
        </div>
        <div>
          <div className="text-xl font-medium text-black">
            Iteration Corporation
          </div>
          <p className="text-gray-500">You can count on us.</p>
        </div>
      </a>
    </div>
  </Layout>
)

export default IndexPage
